@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mulish&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mulish:wght@500&display=swap);
.ashtvivah-logo {
  width: 65%;
}

.me-auto {
  text-align: center;
}

.navbar-light .navbar-nav .nav-link {
  font-weight: 500;
}

.nav-link:hover {
  color: var(--primary) !important;
}

.navbar {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.form-label {
  font-size: 12px;
}
.showProfileButton button {
  font-size: 12px;
}

.navbar-brand {
  font-family: Mulish;
  font-size: 36px !important;
  font-weight: 400;
  color: var(--primary) !important;
}

.dropdown-menu {
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.dropdown-menu .show {
  padding: 6% !important;
}

.dropdown-item {
  font-weight: 400 !important;
}

.dropdown-menu .dropdown-item .nav-link {
  font-weight: 400 !important;
}

.badge {
  background-color: var(--primary) !important;
  width: 20px;
  border-radius: 50% !important;
}

.userClickIconList {
  position: absolute;
  width: 96%;
  transform: translate(-72%, 6%);
}

/* Media query */
@media (max-width: 767px) {
  #basic-nav-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar-brand {
    margin-right: auto !important;
    width: 60%;
  }
  .ashtvivah-logo {
    width: 90%;
  }
}
@media (max-width: 400px) {
  .position-relative {
    align-items: center;
  }
  #basic-nav-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar-brand {
    margin-right: auto !important;
    width: 60%;
  }
  .ashtvivah-logo {
    width: 90%;
  }
}

.footer-component {
  position: relative;
  clear: both;
  text-align: center;
}

.footer {
  margin: 1% 0;
}

.footer .row {
  justify-content: center;
  padding: 0.6% 0%;
  color: #fff;
  font-size: 0.8em;
}

.footer .row a {
  text-decoration: none;
  transition: 0.5s;
  font-size: 18px;
  padding: 20px;
  margin: 10px;
  color: var(--primary) !important;
}

.footer .row a:hover {
  color: #91042e !important;
}

.footer .row ul {
  width: 100%;
}

.footer .row ul li {
  display: inline-block;
  margin: 0px 30px;
}

.footer .row a i {
  font-size: 2em;
  margin: 0% 1%;
}

@media (max-width: 720px) {
  .footer {
    width: 100%;
    margin: 5% auto;
    bottom: 0;
  }
  .footer .row ul li {
    display: block;
    margin: 10px 0px;
    text-align: left;
  }
  .footer .row a i {
    margin: 0% 3%;
  }
}

ul {
  margin: 0px;
  padding: 0px;
}
.footer-section {
  background-color: #ebebeb;
  position: relative;
}
.footer-cta {
  border-bottom: 1px solid #373636;
}
.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #757575;
  font-size: 15px;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}
.footer-logo {
  margin-bottom: 30px;
}
.footer-logo img {
  max-width: 200px;
}
.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #7e7e7e;
  line-height: 28px;
}
.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}
.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}
.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}
.facebook-bg {
  background: #3b5998;
}
.twitter-bg {
  background: #55acee;
}
.instagram-bg {
  background: #dd4b39;
}
.youtube-bg {
  background: #dd4b39;
}
.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}
.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #ff5e14;
}
.footer-widget ul li {
  display: inline-block;
  float: left;
  width: 50%;
  margin-bottom: 12px;
}
.footer-widget ul li a:hover {
  color: #ff5e14;
}
.footer-widget ul li a {
  color: #878787;
  text-transform: capitalize;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2e2e2e;
  border: 1px solid #2e2e2e;
  color: #fff;
}
.subscribe-form button {
  position: absolute;
  right: 0;
  background: #ff5e14;
  padding: 13px 20px;
  border: 1px solid #ff5e14;
  top: 0;
}
.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}
.copyright-area {
  background: #000000;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #ffffff;
}
.copyright-text p a {
  color: #ff5e14;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a {
  color: #ff5e14;
}
.footer-menu li a {
  font-size: 14px;
  color: #878787;
}

.profile-info {
  font-weight: 700;
  padding: 10px 0;
  text-align: justify;
}

.pp_username {
  color: black;
  font-size: 24px;
  font-weight: 700;
  padding: 5px;
}

.pp_details {
  color: black;
  font-weight: 400;
  font-size: 18px;
}

.field-name{
    text-transform: uppercase;
    font-weight: 700;
}

/* @import url("https://fonts.googleapis.com/css2?family=Mulish:wght@500&display=swap"); */

* {
  font-family: Mulish, Georgia, "Times New Roman", Times, serif;
}

:root {
  --primary: #d30037;
  font-family: Mulish, Georgia, "Times New Roman", Times, serif;
}

.App {
  background: ghostwhite;
  font-family: Mulish, Georgia, "Times New Roman", Times, serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.last-word {
  color: var(--primary);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.nav-pills .nav-link.active {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  color: #111111 !important;
}

.nav-pills .nav-link.active .nav-link.focus {
  color: #fff !important;
}
.nav-link {
  color: #282c34 !important;
}

.page-title {
  font-size: 44px;
  text-align: center;
  font-weight: 600;
}

.spinner-div {
  display: flex;
  justify-content: center;
  padding: 13% 0;
}

.website-sub-title {
  padding-top: 4rem;
  font-family: Mulish;
  font-style: normal;
  font-size: 18px;
  text-align: center;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: #161722;
}

@media screen and (max-width: 400px) {
  .website-sub-title {
    padding-top: 0;
  }
}

.form-label {
  text-align: center;
  text-transform: uppercase;
}

.home-header {
  margin-top: 4rem;
}

.tab-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-banner {
  height: 100%;
  width: 100%;
  border-radius: 5%;
}

.registerDetailsComponent {
  padding: 4px;
  min-height: 100%;
  width: cover;
  position: relative;
  background-size: contain;
}

.left-side {
  text-align: center;
  padding-top: 3%;
  background-color: #f7e9d7 !important;
}

/* .update-profile-name {
  margin-top: 4rem;
} */

.left-side a {
  text-transform: uppercase;
  margin: 1% 0;
}

.right-side {
  padding: 5% 0;
  margin: auto;
  height: 100%;
}

.tab-content {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  padding-top: 5%;
  flex-direction: row;
}

.background-image {
  background-image: url(/static/media/rahul-wedding.4392ff8e.jpg);
  background-size: cover;
  background-position: top;
  min-height: 100%;
  width: cover;
  position: relative;
  opacity: 0.6;
}

.website-header {
  display: none;
  color: #fff;
  margin: 0;
  position: absolute;
  top: 50%;
  text-transform: lowercase;
  font-size: 80px;
  transform: translateY(-50%);
}

.user-details {
  font-size: 12px;
}
.selected-plan {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  padding: 10px 0;
}

.btn-danger {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
}

.nav-pills .nav-link {
  font-weight: 700;
}

.text-size-14 {
  font-size: 14px;
}

.marathi-tagline {
  font-weight: 700;
  padding: 2%;
  font-size: 54px;
  text-align: center;
  color: var(--primary);
  text-decoration: underline;
}

.free-tagline {
  font-size: 18px;
  text-align: center;
}

.why-choose-us-fe {
  padding: 15px;
}

.image-div {
  width: 100%;
}

.home-image {
  opacity: 0.1;
  max-width: 100%;
}

.firstline {
  color: var(--primary);
}

.secondline {
  font-weight: 500;
  text-align: center;
}
/* Media query */
@media (max-width: 767px) {
  .marathi-tagline {
    font-size: 32px;
  }

  .free-tagline {
    font-size: 22px;
  }

  .home-banner {
    display: contents;
  }
}

@media (max-width: 400px) {
  .marathi-tagline {
    font-size: 22px;
  }
  .why-choose-us-fe {
    padding: 0;
    margin: 0 0 !important;
  }

  .home-banner {
    display: contents;
  }

  .free-tagline {
    font-size: 22px;
  }
  .registerDetailsComponent {
    margin: 2rem 0;
  }
}

.close-filter {
  float: right;
  font-size: 14px;
  text-decoration: underline;
  color: var(--primary);
  cursor: pointer;
}

.filter {
  margin-top: 5rem;
  font-size: 12px;
}
.find-a-partner {
  font-size: 14px;
  float: left;
  text-decoration: underline;
  color: var(--primary);
}

.verified-logo {
  color: #0d6efd;
}

.filter-div {
  padding: 10px;
  background-color: #4c5fbb12;
}

.filter-form {
  justify-content: center;
}

* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}
.list-of-profile-button {
  margin: 5px;
  font-size: 10px;
  text-transform: uppercase;
}

.showProfileButton {
  margin-top: 30px;
}

.text-center {
  text-align: center;
}

.profile-image {
  margin: 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* LIST OF PROFILES */

.card-image {
  width: 100px;
  height: 100px;
  cursor: pointer;
}

.card-body {
  padding: 0 1rem;
}

.profile-outer-card {
  height: 100%;
  border: none;
  padding: 5px;
  border-radius: 10px;
  margin: 10px;
  box-shadow: 3px 7px 18px 1px rgba(0, 0, 0, 0.53);
}

.notify-badge {
  position: absolute;
  right: 6%;
  top: 10px;
  border-radius: 30px 30px 30px 30px;
  font-size: 22px;
}

.gender {
  top: 44px;
  text-transform: uppercase;
}

.pagination {
  margin-top: 5%;
  justify-content: center;
}

/* .header-fresh-recommand {
  font-family: Mulish;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  text-align: center;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  margin-bottom: 2rem;
  color: #161722;
} */

.profile-list-component {
  margin: 3%;
  /* overflow: scroll;
  height: 600px; */
}

.pagination-item-link .page-link {
  font-size: 12px;
}

.profile-name-div {
  height: 75px;
  width: 75px;
  border-radius: 5% !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

.initial-letter {
  text-align: center;
  line-height: 4.5rem;
  font-size: 28px;
  font-weight: 600;
  color: rgb(0, 73, 183);
}

.page-link {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  color: #111111;
  margin: 0 10px;
  font-weight: 700;
}

.page-item.active .page-link {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  color: #ffffff;
}

.profile-hashtag {
  background: #0d6efd;
  border-radius: 30px 30px 30px 30px;
  color: white;
  padding: 5px 10px;
  font-size: 10px;
}

.btn-link {
  border: none;
  color: #007bff;
  text-align: center;
  /* display: inline-block; */
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.profile-settings-btn {
  background-color: #f0f0f0 !important;
  margin: 10px 0;
  padding: 10px;
}

.profile-settings-btn p {
  font-size: 12px;
}

@media screen and (max-width: 992px) {
  .filter {
    margin-top: 0;
  }

  .list-of-profile-button {
    width: 100%;
  }

  .form-label {
    font-weight: 100 !important;
  }
}

@media screen and (max-width: 400px) {
  .filter {
    margin-top: 0;
  }

  .website-sub-title {
    padding-top: 0;
  }

  .filters {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: flex-end;
    padding: 4px;
    align-items: center;
  }

  .list-of-profile-button {
    width: 100%;
  }

  .form-label {
    font-weight: 100 !important;
  }

  .find-a-partner {
    font-weight: unset !important;
  }
}



