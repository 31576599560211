@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Mulish:wght@500&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");

* {
  font-family: Mulish, Georgia, "Times New Roman", Times, serif;
}

:root {
  --primary: #d30037;
  font-family: Mulish, Georgia, "Times New Roman", Times, serif;
}

.App {
  background: ghostwhite;
  font-family: Mulish, Georgia, "Times New Roman", Times, serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.last-word {
  color: var(--primary);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.nav-pills .nav-link.active {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  color: #111111 !important;
}

.nav-pills .nav-link.active .nav-link.focus {
  color: #fff !important;
}
.nav-link {
  color: #282c34 !important;
}

.page-title {
  font-size: 44px;
  text-align: center;
  font-weight: 600;
}

.spinner-div {
  display: flex;
  justify-content: center;
  padding: 13% 0;
}

.website-sub-title {
  padding-top: 4rem;
  font-family: Mulish;
  font-style: normal;
  font-size: 18px;
  text-align: center;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: #161722;
}

@media screen and (max-width: 400px) {
  .website-sub-title {
    padding-top: 0;
  }
}
