.close-filter {
  float: right;
  font-size: 14px;
  text-decoration: underline;
  color: var(--primary);
  cursor: pointer;
}

.filter {
  margin-top: 5rem;
  font-size: 12px;
}
.find-a-partner {
  font-size: 14px;
  float: left;
  text-decoration: underline;
  color: var(--primary);
}

.verified-logo {
  color: #0d6efd;
}

.filter-div {
  padding: 10px;
  background-color: #4c5fbb12;
}

.filter-form {
  justify-content: center;
}

* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}
.list-of-profile-button {
  margin: 5px;
  font-size: 10px;
  text-transform: uppercase;
}

.showProfileButton {
  margin-top: 30px;
}

.text-center {
  text-align: center;
}

.profile-image {
  margin: 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* LIST OF PROFILES */

.card-image {
  width: 100px;
  height: 100px;
  cursor: pointer;
}

.card-body {
  padding: 0 1rem;
}

.profile-outer-card {
  height: 100%;
  border: none;
  padding: 5px;
  border-radius: 10px;
  margin: 10px;
  -webkit-box-shadow: 3px 7px 18px 1px rgba(0, 0, 0, 0.53);
  -moz-box-shadow: 3px 7px 18px 1px rgba(0, 0, 0, 0.53);
  box-shadow: 3px 7px 18px 1px rgba(0, 0, 0, 0.53);
}

.notify-badge {
  position: absolute;
  right: 6%;
  top: 10px;
  border-radius: 30px 30px 30px 30px;
  font-size: 22px;
}

.gender {
  top: 44px;
  text-transform: uppercase;
}

.pagination {
  margin-top: 5%;
  justify-content: center;
}

/* .header-fresh-recommand {
  font-family: Mulish;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  text-align: center;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  margin-bottom: 2rem;
  color: #161722;
} */

.profile-list-component {
  margin: 3%;
  /* overflow: scroll;
  height: 600px; */
}

.pagination-item-link .page-link {
  font-size: 12px;
}

.profile-name-div {
  height: 75px;
  width: 75px;
  border-radius: 5% !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

.initial-letter {
  text-align: center;
  line-height: 4.5rem;
  font-size: 28px;
  font-weight: 600;
  color: rgb(0, 73, 183);
}

.page-link {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  color: #111111;
  margin: 0 10px;
  font-weight: 700;
}

.page-item.active .page-link {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  color: #ffffff;
}

.profile-hashtag {
  background: #0d6efd;
  border-radius: 30px 30px 30px 30px;
  color: white;
  padding: 5px 10px;
  font-size: 10px;
}

.btn-link {
  border: none;
  color: #007bff;
  text-align: center;
  /* display: inline-block; */
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.profile-settings-btn {
  background-color: #f0f0f0 !important;
  margin: 10px 0;
  padding: 10px;
}

.profile-settings-btn p {
  font-size: 12px;
}

@media screen and (max-width: 992px) {
  .filter {
    margin-top: 0;
  }

  .list-of-profile-button {
    width: 100%;
  }

  .form-label {
    font-weight: 100 !important;
  }
}

@media screen and (max-width: 400px) {
  .filter {
    margin-top: 0;
  }

  .website-sub-title {
    padding-top: 0;
  }

  .filters {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: flex-end;
    padding: 4px;
    align-items: center;
  }

  .list-of-profile-button {
    width: 100%;
  }

  .form-label {
    font-weight: 100 !important;
  }

  .find-a-partner {
    font-weight: unset !important;
  }
}
