.ashtvivah-logo {
  width: 65%;
}

.me-auto {
  text-align: center;
}

.navbar-light .navbar-nav .nav-link {
  font-weight: 500;
}

.nav-link:hover {
  color: var(--primary) !important;
}

.navbar {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.form-label {
  font-size: 12px;
}
.showProfileButton button {
  font-size: 12px;
}

.navbar-brand {
  font-family: Mulish;
  font-size: 36px !important;
  font-weight: 400;
  color: var(--primary) !important;
}

.dropdown-menu {
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.dropdown-menu .show {
  padding: 6% !important;
}

.dropdown-item {
  font-weight: 400 !important;
}

.dropdown-menu .dropdown-item .nav-link {
  font-weight: 400 !important;
}

.badge {
  background-color: var(--primary) !important;
  width: 20px;
  border-radius: 50% !important;
}

.userClickIconList {
  position: absolute;
  width: 96%;
  transform: translate(-72%, 6%);
}

/* Media query */
@media (max-width: 767px) {
  #basic-nav-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar-brand {
    margin-right: auto !important;
    width: 60%;
  }
  .ashtvivah-logo {
    width: 90%;
  }
}
@media (max-width: 400px) {
  .position-relative {
    align-items: center;
  }
  #basic-nav-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar-brand {
    margin-right: auto !important;
    width: 60%;
  }
  .ashtvivah-logo {
    width: 90%;
  }
}
