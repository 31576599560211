.form-label {
  text-align: center;
  text-transform: uppercase;
}

.home-header {
  margin-top: 4rem;
}

.tab-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-banner {
  height: 100%;
  width: 100%;
  border-radius: 5%;
}

.registerDetailsComponent {
  padding: 4px;
  min-height: 100%;
  width: cover;
  position: relative;
  background-size: contain;
}

.left-side {
  text-align: center;
  padding-top: 3%;
  background-color: #f7e9d7 !important;
}

/* .update-profile-name {
  margin-top: 4rem;
} */

.left-side a {
  text-transform: uppercase;
  margin: 1% 0;
}

.right-side {
  padding: 5% 0;
  margin: auto;
  height: 100%;
}

.tab-content {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  padding-top: 5%;
  flex-direction: row;
}

.background-image {
  background-image: url(../images/rahul-wedding.jpg);
  background-size: cover;
  background-position: top;
  min-height: 100%;
  width: cover;
  position: relative;
  opacity: 0.6;
}

.website-header {
  display: none;
  color: #fff;
  margin: 0;
  position: absolute;
  top: 50%;
  text-transform: lowercase;
  font-size: 80px;
  transform: translateY(-50%);
}

.user-details {
  font-size: 12px;
}
.selected-plan {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  text-decoration-line: underline;
  padding: 10px 0;
}

.btn-danger {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
}

.nav-pills .nav-link {
  font-weight: 700;
}

.text-size-14 {
  font-size: 14px;
}

.marathi-tagline {
  font-weight: 700;
  padding: 2%;
  font-size: 54px;
  text-align: center;
  color: var(--primary);
  text-decoration: underline;
}

.free-tagline {
  font-size: 18px;
  text-align: center;
}

.why-choose-us-fe {
  padding: 15px;
}

.image-div {
  width: 100%;
}

.home-image {
  opacity: 0.1;
  max-width: 100%;
}

.firstline {
  color: var(--primary);
}

.secondline {
  font-weight: 500;
  text-align: center;
}
/* Media query */
@media (max-width: 767px) {
  .marathi-tagline {
    font-size: 32px;
  }

  .free-tagline {
    font-size: 22px;
  }

  .home-banner {
    display: contents;
  }
}

@media (max-width: 400px) {
  .marathi-tagline {
    font-size: 22px;
  }
  .why-choose-us-fe {
    padding: 0;
    margin: 0 0 !important;
  }

  .home-banner {
    display: contents;
  }

  .free-tagline {
    font-size: 22px;
  }
  .registerDetailsComponent {
    margin: 2rem 0;
  }
}
