.profile-info {
  font-weight: 700;
  padding: 10px 0;
  text-align: justify;
}

.pp_username {
  color: black;
  font-size: 24px;
  font-weight: 700;
  padding: 5px;
}

.pp_details {
  color: black;
  font-weight: 400;
  font-size: 18px;
}

.field-name{
    text-transform: uppercase;
    font-weight: 700;
}
